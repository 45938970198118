import { cookieOverride } from '../cookie-override/cookie-override';
import {
    createRandomPlayerDistribution,
    IsPlayerInBucket,
} from '../random-player-distribution/create-random-player-distribution';
import { isInTestGroupWithCookieOverride } from './is-in-test-group-with-cookie-override';

interface ABCDPlayerDistributionParams {
    bucketPercentages: [number, number, number, number];
    seed: string;
    name: string;
}

export const createABCDTest = (
    params: ABCDPlayerDistributionParams
): [IsPlayerInBucket, IsPlayerInBucket, IsPlayerInBucket, IsPlayerInBucket] => {
    const groupACookieOverride = cookieOverride(params.name, 'A');
    const groupBCookieOverride = cookieOverride(params.name, 'B');
    const groupCCookieOverride = cookieOverride(params.name, 'C');
    const groupDCookieOverride = cookieOverride(params.name, 'D');

    const [isPlayerInBucketA, isPlayerInBucketB, isPlayerInBucketC, isPlayerInBucketD] =
        createRandomPlayerDistribution(params);

    return [
        isInTestGroupWithCookieOverride(groupACookieOverride, isPlayerInBucketA),
        isInTestGroupWithCookieOverride(groupBCookieOverride, isPlayerInBucketB),
        isInTestGroupWithCookieOverride(groupCCookieOverride, isPlayerInBucketC),
        isInTestGroupWithCookieOverride(groupDCookieOverride, isPlayerInBucketD),
    ];
};
